<template>
  <h3 style="margin:25px;padding:15px;text-align:center;background:#fff;">
    {{ text }}
  </h3>
</template>

<script>
import { toRefs, reactive, onMounted, inject } from 'vue'
import { Toast } from 'vant'
import wx from 'weixin-js-sdk'
// import Cookies from 'js-cookie'

export default {
  setup () {
    const post = inject('post')
    const useRoute = inject('useRoute')
    const state = reactive({
      orderId: useRoute.query.orderId,
      text: '正在调起扫一扫，请稍候'
    })
    const init = () => {
      var u = navigator.userAgent
      var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
      var url = location.href
      if (isiOS) {
        url = window.enterurl
      }
      Toast.loading({ duration: 0, forbidClick: true })
      post('/wechat.getJssdk', {
        url: url
      }).then(res => {
        Toast.clear()
        wx.config({
          debug: false,
          appId: res.data.appId,
          timestamp: res.data.timestamp,
          nonceStr: res.data.nonceStr,
          signature: res.data.signature,
          jsApiList: ['scanQRCode']
        })
        wx.ready(() => {
          wx.scanQRCode({
            needResult: 1,
            scanType: ['qrCode', 'barCode'],
            success: res => {
              const result = res.resultStr
              Toast.loading({ forbidClick: true })
              post('/order.openIotDevice', {
                orderId: state.orderId,
                deviceSn: result
              }).then(res => {
                Toast.clear()
                if (res.code === 0) {
                  state.text = '扫码成功'
                } else {
                  state.text = res.msg
                }
              })
            },
            cancel: res => {
              state.text = '扫码已取消'
            }
          })
        })
        wx.error(res => {
          alert(JSON.stringify(res))
        })
      })
    }
    onMounted(() => {
      init()
    })
    return {
      ...toRefs(state)
    }
  }
}
</script>

<style scoped>
</style>
